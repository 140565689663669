import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRelayPointDetailLaunched } from '../../../templates/ShoppingDeliveryPage/reducer';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { MapDetail } from '../MapDetail';

const num = (str) => {
  const splitStr = str.split(",");
  return parseFloat(splitStr[0] + '.' + splitStr[1]);
}

export const MapRelayPoint = ({ arrayOfRelayPoint, setDetailsIsOpen, setRelayPointSelected, relayPointSelected }) => {
  const [map, setMap] = useState(null);
  const [defaultPosition, setDefaultPosition] = useState();

  const [openDetails, setOpenDetails] = useState(false);
  const showDetails = () => {
    setOpenDetails(true);
  };
  const hideDetails = () => {
    setOpenDetails(false);
  };
  const [id, setId] = useState();

  const dispatch = useDispatch();
  const { relayPointDetail, loading, RPDetailStatutCode } = useSelector((state) => ({
    relayPointDetail: state.getIn(['shoppingDeliveryPage', 'relayPointDetail']),
    loading: state.getIn(['shoppingDeliveryPage', 'detailIssLoading']),
    RPDetailStatutCode: state.getIn(['shoppingDeliveryPage', 'RPDetailStatutCode'])
  }));

  const getRelayPointDetail = (item) => {
    if (item.CP[0] !== '' && item.Pays[0] !== '' && item.Num[0]) {
      dispatch(getRelayPointDetailLaunched({
        country: item.Pays[0],
        zipCode: item.CP[0],
        idRelayPoint: item.Num[0],
        action: "24R",
        resultsNumber: 30,
      }))
    }
  };

  const selectRelayPoint = (relayPointDetail) => {
    setRelayPointSelected({
      name: relayPointDetail[0].LgAdr1[0],
      address: relayPointDetail[0].LgAdr3[0],
      zipCodeCity: relayPointDetail[0].CP[0] + ' ' + relayPointDetail[0].Ville[0],
      country: relayPointDetail[0].Pays[0],
      id: relayPointDetail[0].Num[0]
    })
  };


  useEffect(() => {
    // at first mount : we defined default Position
    if (!defaultPosition && arrayOfRelayPoint.length > 0 && arrayOfRelayPoint[0]) {
      setDefaultPosition([num(arrayOfRelayPoint[0].Latitude[0]), num(arrayOfRelayPoint[0].Longitude[0])]);
    }
  }, arrayOfRelayPoint)

  // Change view on new search
  const changeView = (coords) => {
    if (map && coords) {
      map.setView(coords, 13);
      return null;
    }
    return null;
  };

  useEffect(() => {
    if (arrayOfRelayPoint[0]) {
      changeView([num(arrayOfRelayPoint[0]?.Latitude[0]), num(arrayOfRelayPoint[0]?.Longitude[0])]);
    }

  }, [arrayOfRelayPoint])

  if (typeof window !== 'undefined') { // ! on build : window is undefined
    // init leaflet in order to generate svg marker
    const L = require('leaflet');

    const arrayOfIcon = [];
    let icon;

    if (defaultPosition && L) {
      arrayOfRelayPoint.forEach((item, index) => {
        icon = L.divIcon({
          html: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" preserveAspectRatio="none" viewBox="0 0 256 256" xml:space="preserve">
                <g transform="translate(128 128) scale(0.72 0.72)" style="">
                  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)" >
                  <path d="M 78.518 33.518 C 78.518 15.007 63.512 0 45 0 S 11.482 15.007 11.482 33.518 c 0 13.831 8.379 25.702 20.336 30.823 C 36.599 73.647 45 90 45 90 l 13.182 -25.659 C 70.139 59.22 78.518 47.349 78.518 33.518 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(228, 91, 69); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <circle cx="45.004000000000005" cy="33.513999999999996" r="22.654" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
                  <text
                    x="44"
                    y="36"
                    fill="rgb(228, 91, 69)"
                    font-family="Verdana"
                    font-size="35"
                    dominant-baseline="middle"
                    text-anchor="middle"
                  >
                    ${index + 1}
                  </text>
                </g>
                </g>
              </svg>`,
          className: "",
          iconSize: [50, 50],
          iconAnchor: [25, 50],
          popupAnchor: [0, -50],
        });
        arrayOfIcon.push(icon);
      });
    }

    return (
      <>
        <MapDetail
          itemDetail={relayPointDetail}
          index={id}
          showDetails={openDetails}
          hideDetails={() => { hideDetails(); setDetailsIsOpen(false) }}
          selectRelayPoint={() => selectRelayPoint(relayPointDetail)}
          relayPointSelected={relayPointSelected}
        />
        {
          (defaultPosition && arrayOfIcon.length > 0) &&
          (
            <MapContainer
              center={defaultPosition}
              zoom={13}
              scrollWheelZoom={true}
              whenCreated={(map) => setMap(map)}
              className="absolute"
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {arrayOfRelayPoint && arrayOfRelayPoint.map((item, index) => (
                <Marker
                  key={index}
                  position={[num(item.Latitude[0]), num(item.Longitude[0])]}
                  icon={arrayOfIcon[index]}
                  eventHandlers={{
                    click: (e) => {
                      setId(index)
                      getRelayPointDetail(item)
                      showDetails();
                    }
                  }}
                >
                  <Popup>
                    {item.LgAdr1}
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          )
        }
        {
          (!defaultPosition && !arrayOfIcon) && ((
            <p>Chargement</p>
          ))
        }
      </>
    )
  }
  return null

};

export default MapRelayPoint;