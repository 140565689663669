import React, { useEffect } from 'react';
import { XIcon, ArrowLeftIcon, CheckIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';

export const RelayPointDetails = ({ itemDetail, index, showDetails, hideDetails, selectRelayPoint, relayPointSelected }) => {

  const formatHour = (string) => {
    return string.slice(0, 2) + 'h' + string.slice(2);
  };

  const { isLoading } = useSelector((state) => ({
    isLoading: state.getIn(['shoppingDeliveryPage', 'isLoading']),
  }));

  useEffect(() => {
    hideDetails();
  }, [isLoading]);

  return (
    (showDetails) && (
      <div
        className="hidden md:block md:absolute md:top-0 z-10 w-full h-full bg-white overflow-y-scroll"
      >
        <div className="flex flex-col w-full">
          <div
            aria-hidden="true"
            className="w-full sticky top-0 h-10 px-4 bg-orange-dark hover:bg-orange-chocolate flex justify-between items-center cursor-pointer"
            onClick={() => hideDetails()}
          >
            <ArrowLeftIcon className="h-4 w-4 text-white" />
            <p className="text-xs text-white hover:text-underline">Retour aux résultats</p>
            <XIcon className="h-4 w-4 text-white" />
          </div>
          {itemDetail && (
            <div className="flex flex-col">
              <div className="flex items-center space-x-4 mt-4">
                <div className="flex-shrink-0">
                  {(!relayPointSelected || (relayPointSelected && (relayPointSelected?.id !== itemDetail[0]?.Num[0]))) && (
                    <p className="h-8 w-8 pt-1.5 rounded-full bg-orange-dark text-white text-center">{index + 1}</p>
                  )}
                  {(relayPointSelected && (relayPointSelected?.id === itemDetail[0]?.Num[0])) && (
                    <p className="h-8 w-8 rounded-full bg-green text-center">
                      <CheckIcon className="mx-auto pt-1 h-7 w-7 text-white" />
                    </p>
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-s font-medium text-gray-900 truncate capitalize">{itemDetail[0].LgAdr1[0]}</p>
                  <p className="text-xs text-gray-500 truncate capitalize">{itemDetail[0].LgAdr3[0]}</p>
                  <p className="text-xs text-gray-500 truncate capitalize">{itemDetail[0].CP[0] + ' ' + itemDetail[0].Ville[0]}</p>
                </div>
              </div>
              <div className="mt-6 mx-auto">
                <button
                  type="button"
                  onClick={() => selectRelayPoint()}
                  className="text-white bg-orange-dark hover:bg-orange-chocolate font-medium rounded-lg text-s px-5 py-1 text-center focus:outline-none"
                >
                  Choisir ce Point Relais
                </button>
              </div>
              {itemDetail[0].URL_Photo[0] && (
                <div className="mt-6 flex justify-left">
                  <img className="mx-auto h-40 w-40 xl:w-56 xl:h-56" src={itemDetail[0].URL_Photo[0]} alt="" />
                </div>
              )}

              <div>
                <dl className="mt-6">
                  <div className="bg-grey-light">
                    <div className="py-2.5 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-xs font-medium text-gray-500">Lundi</dt>
                      <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2 md:space-x-2 flex flex-col md:flex-row">
                        {(itemDetail[0].Horaires_Lundi[0].string[0] === "0000"
                          && itemDetail[0].Horaires_Lundi[0].string[1] === "0000"
                          && itemDetail[0].Horaires_Lundi[0].string[2] === "0000"
                          && itemDetail[0].Horaires_Lundi[0].string[3] === "0000")
                          && (
                            <p>Fermé</p>
                          )}
                        {(itemDetail[0].Horaires_Lundi[0].string[0] !== "0000"
                          && itemDetail[0].Horaires_Lundi[0].string[1] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Lundi[0].string[0])} - ${formatHour(itemDetail[0].Horaires_Lundi[0].string[1])}`}</p>
                          )}
                        {(itemDetail[0].Horaires_Lundi[0].string[2] !== "0000" && itemDetail[0].Horaires_Lundi[0].string[3] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Lundi[0].string[2])} - ${formatHour(itemDetail[0].Horaires_Lundi[0].string[3])}`}</p>
                          )}
                      </dd>
                    </div>
                  </div>
                  <div className="bg-white">
                    <div className="py-2.5 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-xs font-medium text-gray-500">Mardi</dt>
                      <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2 md:space-x-2 flex flex-col md:flex-row">
                        {(itemDetail[0].Horaires_Mardi[0].string[0] === "0000"
                          && itemDetail[0].Horaires_Mardi[0].string[1] === "0000"
                          && itemDetail[0].Horaires_Mardi[0].string[2] === "0000"
                          && itemDetail[0].Horaires_Mardi[0].string[3] === "0000")
                          && (
                            <p>Fermé</p>
                          )}
                        {(itemDetail[0].Horaires_Mardi[0].string[0] !== "0000"
                          && itemDetail[0].Horaires_Mardi[0].string[1] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Mardi[0].string[0])} - ${formatHour(itemDetail[0].Horaires_Mardi[0].string[1])}`}</p>
                          )}
                        {(itemDetail[0].Horaires_Mardi[0].string[2] !== "0000" && itemDetail[0].Horaires_Mardi[0].string[3] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Mardi[0].string[2])} - ${formatHour(itemDetail[0].Horaires_Mardi[0].string[3])}`}</p>
                          )}
                      </dd>
                    </div>
                  </div>
                  <div className="bg-grey-light">
                    <div className="py-2.5 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-xs font-medium text-gray-500">Mercredi</dt>
                      <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2 md:space-x-2 flex flex-col md:flex-row">
                        {(itemDetail[0].Horaires_Mercredi[0].string[0] === "0000"
                          && itemDetail[0].Horaires_Mercredi[0].string[1] === "0000"
                          && itemDetail[0].Horaires_Mercredi[0].string[2] === "0000"
                          && itemDetail[0].Horaires_Mercredi[0].string[3] === "0000")
                          && (
                            <p>Fermé</p>
                          )}
                        {(itemDetail[0].Horaires_Mercredi[0].string[0] !== "0000"
                          && itemDetail[0].Horaires_Mercredi[0].string[1] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Mercredi[0].string[0])} - ${formatHour(itemDetail[0].Horaires_Mercredi[0].string[1])}`}</p>
                          )}
                        {(itemDetail[0].Horaires_Mercredi[0].string[2] !== "0000" && itemDetail[0].Horaires_Mercredi[0].string[3] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Mercredi[0].string[2])} - ${formatHour(itemDetail[0].Horaires_Mercredi[0].string[3])}`}</p>
                          )}
                      </dd>
                    </div>
                  </div>
                  <div className="bg-white">
                    <div className="py-2.5 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sxs font-medium text-gray-500">Jeudi</dt>
                      <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2 md:space-x-2 flex flex-col md:flex-row">
                        {(itemDetail[0].Horaires_Jeudi[0].string[0] === "0000"
                          && itemDetail[0].Horaires_Jeudi[0].string[1] === "0000"
                          && itemDetail[0].Horaires_Jeudi[0].string[2] === "0000"
                          && itemDetail[0].Horaires_Jeudi[0].string[3] === "0000")
                          && (
                            <p>Fermé</p>
                          )}
                        {(itemDetail[0].Horaires_Jeudi[0].string[0] !== "0000"
                          && itemDetail[0].Horaires_Jeudi[0].string[1] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Jeudi[0].string[0])} - ${formatHour(itemDetail[0].Horaires_Jeudi[0].string[1])}`}</p>
                          )}
                        {(itemDetail[0].Horaires_Jeudi[0].string[2] !== "0000" && itemDetail[0].Horaires_Jeudi[0].string[3] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Jeudi[0].string[2])} - ${formatHour(itemDetail[0].Horaires_Jeudi[0].string[3])}`}</p>
                          )}
                      </dd>
                    </div>
                  </div>
                  <div className="bg-grey-light">
                    <div className="py-2.5 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-xs font-medium text-gray-500">Vendredi</dt>
                      <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2 md:space-x-2 flex flex-col md:flex-row">
                        {(itemDetail[0].Horaires_Vendredi[0].string[0] === "0000"
                          && itemDetail[0].Horaires_Vendredi[0].string[1] === "0000"
                          && itemDetail[0].Horaires_Vendredi[0].string[2] === "0000"
                          && itemDetail[0].Horaires_Vendredi[0].string[3] === "0000")
                          && (
                            <p>Fermé</p>
                          )}
                        {(itemDetail[0].Horaires_Vendredi[0].string[0] !== "0000"
                          && itemDetail[0].Horaires_Vendredi[0].string[1] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Vendredi[0].string[0])} - ${formatHour(itemDetail[0].Horaires_Vendredi[0].string[1])}`}</p>
                          )}
                        {(itemDetail[0].Horaires_Vendredi[0].string[2] !== "0000" && itemDetail[0].Horaires_Vendredi[0].string[3] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Vendredi[0].string[2])} - ${formatHour(itemDetail[0].Horaires_Vendredi[0].string[3])}`}</p>
                          )}
                      </dd>
                    </div>
                  </div>
                  <div className="bg-white">
                    <div className="py-2.5 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sxs font-medium text-gray-500">Samedi</dt>
                      <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2 md:space-x-2 flex flex-col md:flex-row">
                        {(itemDetail[0].Horaires_Samedi[0].string[0] === "0000"
                          && itemDetail[0].Horaires_Samedi[0].string[1] === "0000"
                          && itemDetail[0].Horaires_Samedi[0].string[2] === "0000"
                          && itemDetail[0].Horaires_Samedi[0].string[3] === "0000")
                          && (
                            <p>Fermé</p>
                          )}
                        {(itemDetail[0].Horaires_Samedi[0].string[0] !== "0000"
                          && itemDetail[0].Horaires_Samedi[0].string[1] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Samedi[0].string[0])} - ${formatHour(itemDetail[0].Horaires_Samedi[0].string[1])}`}</p>
                          )}
                        {(itemDetail[0].Horaires_Samedi[0].string[2] !== "0000" && itemDetail[0].Horaires_Samedi[0].string[3] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Samedi[0].string[2])} - ${formatHour(itemDetail[0].Horaires_Samedi[0].string[3])}`}</p>
                          )}
                      </dd>
                    </div>
                  </div>
                  <div className="bg-grey-light">
                    <div className="py-2.5 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-xs font-medium text-gray-500">Dimanche</dt>
                      <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2 md:space-x-2 flex flex-col md:flex-row">
                        {(itemDetail[0].Horaires_Dimanche[0].string[0] === "0000"
                          && itemDetail[0].Horaires_Dimanche[0].string[1] === "0000"
                          && itemDetail[0].Horaires_Dimanche[0].string[2] === "0000"
                          && itemDetail[0].Horaires_Dimanche[0].string[3] === "0000")
                          && (
                            <p>Fermé</p>
                          )}
                        {(itemDetail[0].Horaires_Dimanche[0].string[0] !== "0000"
                          && itemDetail[0].Horaires_Dimanche[0].string[1] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Dimanche[0].string[0])} - ${formatHour(itemDetail[0].Horaires_Dimanche[0].string[1])}`}</p>
                          )}
                        {(itemDetail[0].Horaires_Dimanche[0].string[2] !== "0000" && itemDetail[0].Horaires_Dimanche[0].string[3] !== "0000")
                          && (
                            <p>{`${formatHour(itemDetail[0].Horaires_Dimanche[0].string[2])} - ${formatHour(itemDetail[0].Horaires_Dimanche[0].string[3])}`}</p>
                          )}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          )}
          {!itemDetail && (
            <p className="text-center mt-10">Chargement ...</p>
          )}
        </div>


      </div>
    )

  )
};

export default RelayPointDetails;
