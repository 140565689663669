
const getStyles = (theme) => ({
  largeModalBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  dynamicHeight: {
    height: 'calc(100vh - 19rem)'
  },
  modalFooter: {
    zIndex: 1051
  }
});

export default getStyles;
