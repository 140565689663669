import React from 'react';

const PriceSummaryBloc = ({
  availableItems,
  totalPriceTTCState,
  deliveryPriceState,
  totalToPayTTCState,
  relaisColisNeedChoice,
  missingDeliveryChoice,
  billingError,
  deliveryError,
  showModal,
  validateShippingConfiguration,
  deliverySimulation,
  processToPaiement
}) => {

  return (
    <div className="flex flex-col justify-around rounded bg-orange w-full p-4 space-y-1">
      <span className="font-semibold text-sm">Récapitulatif</span>
      <span>
        Nombre d'articles :
        <span className="font-medium pl-2">
          {availableItems}
        </span>
      </span>
      <span>
        Prix TTC :
        <span className="font-medium pl-2">
          {(totalPriceTTCState.totalPriceTTC !== '...') ? (totalPriceTTCState.totalPriceTTC / 100).toFixed(2) : totalPriceTTCState.totalPriceTTC} €
        </span>
      </span>
      <span>
        Frais de livraison :
        <span className="font-medium pl-2">
          {(deliveryPriceState.deliveryPrice !== '...') ? ((deliveryPriceState.deliveryPrice === 0) ? 'Gratuite' : `${(deliveryPriceState.deliveryPrice / 100).toFixed(2)} €`) : `${deliveryPriceState.deliveryPrice} €`}
        </span>
      </span>
      <span className="pt-2">
        <p className="text-s font-semibold">
          Total à payer :
          <span>
            <span className="pl-2">{(totalToPayTTCState.totalToPayTTC !== '...') ? (totalToPayTTCState.totalToPayTTC / 100).toFixed(2) : totalToPayTTCState.totalToPayTTC} €</span>
          </span>
        </p>
        <p className="text-xxm">
          (TVA incluse {(process.env.GATSBY_VAT * 100)}%)
        </p>
      </span>
      {(relaisColisNeedChoice || missingDeliveryChoice) && (
        <div className="pt-4 text-red space-y-2">
          {missingDeliveryChoice && (
            <p>Veuillez selectionner le/les mode(s) de livraison manquant(s).</p>
          )}
          {deliveryError && (
            <p>Veuillez compléter votre adresse de livraison.</p>
          )}
          {billingError && (
            <p>Veuillez compléter votre adresse de facturation.</p>
          )}
          {relaisColisNeedChoice && (
            <div className="flex flex-col items-center space-y-1 text-s px-2 py-2">
              <p>Vous avez choisi un mode de livraison "Relais-colis" mais aucun point relais n'est sélectionné.</p>
              <button
                type="button"
                onClick={showModal}
                data-modal-toggle="mapModal"
                className="outline-none w-full rounded py-4 px-2 border border-transparent text-white focus:outline-none focus:ring-0 focus:ring-offset-0 bg-orange-dark hover:bg-orange-chocolate hover:font-semibold"
              >
                Sélectionner un point relais
              </button>
            </div>
          )}
        </div>
      )}
      {!processToPaiement && (
        <div className="self-center lg:self-start text-s px-2 py-2 font-bold w-full">
          <button
            type="button"
            onClick={() => { validateShippingConfiguration(); window?.scrollTo(0, 0); }}
            disabled={!deliverySimulation || relaisColisNeedChoice || missingDeliveryChoice}
            className={`outline-none w-full rounded py-4 px-2 border border-transparent text-white focus:outline-none focus:ring-0 focus:ring-offset-0 ${(!deliverySimulation || relaisColisNeedChoice || missingDeliveryChoice) ? 'bg-grey' : 'bg-orange-dark hover:bg-orange-chocolate hover:font-semibold'}`}
          >
            Valider et passer au paiement
          </button>
        </div>
      )
      }
    </div >
  );

};

export default PriceSummaryBloc;