import React from 'react';
import { Field } from 'formik';
import countriesJSON from '../../../utils/countries.json';

const BillingFormBloc = ({
  values,
  handleChange,
  setBillingFormValue,
  errors,
  touched
}) => {

  return (
    <div className="w-full xl:w-2/3 2xl:w-1/2 px-2 bg-white sm:p-6">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label className="block text-s font-medium" htmlFor="billLastName">
            Nom*
          </label>
          <Field
            id="billLastName"
            name="billLastName"
            type="text"
            values={values.billLastName}
            onChange={(e) => {
              handleChange(e);
              setBillingFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            style="appearance: none"
          />
          {errors.billLastName && touched.billLastName ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.billLastName}</p>
          ) : null}
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label className="block text-s font-medium" htmlFor="billFirstName">
            Prénom*
          </label>
          <Field
            id="billFirstName"
            name="billFirstName"
            type="text"
            values={values.billFirstName}
            onChange={(e) => {
              handleChange(e);
              setBillingFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.billFirstName && touched.billFirstName ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.billFirstName}</p>
          ) : null}
        </div>
        <div className="col-span-6">
          <label className="block text-s font-medium" htmlFor="billCompany">
            Société
          </label>
          <Field
            id="billCompany"
            name="billCompany"
            type="text"
            values={values.billCompany}
            onChange={(e) => {
              handleChange(e);
              setBillingFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.billCompany && touched.billCompany ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.billCompany}</p>
          ) : null}
        </div>

        <div className="col-span-6">
          <label htmlFor="billAddress" className="block text-s font-medium">
            Adresse*
          </label>
          <Field
            id="billAddress"
            name="billAddress"
            type="text"
            values={values.billAddress}
            onChange={(e) => {
              handleChange(e);
              setBillingFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.billAddress && touched.billAddress ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.billAddress}</p>
          ) : null}
        </div>
        <div className="col-span-6 sm:col-span-6 lg:col-span-3">
          <label htmlFor="billCity" className="block text-s font-medium">
            Ville*
          </label>
          <Field
            id="billCity"
            name="billCity"
            type="text"
            values={values.billCity}
            onChange={(e) => {
              handleChange(e);
              setBillingFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.billCity && touched.billCity ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.billCity}</p>
          ) : null}
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="billZipCode" className="block text-s font-medium">
            CP*
          </label>
          <Field
            id="billZipCode"
            name="billZipCode"
            type="text"
            values={values.billZipCode}
            onChange={(e) => {
              handleChange(e);
              setBillingFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.billZipCode && touched.billZipCode ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.billZipCode}</p>
          ) : null}
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-3 ">
          <label htmlFor="billCountry" className="block text-s font-medium">
            Pays*
          </label>
          <select
            id="billCountry"
            name="billCountry"
            type="text"
            value={values.billCountry}
            onChange={(e) => {
              handleChange(e);
              setBillingFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          >
            <option value=""> Choisissez un pays </option>
            {
              countriesJSON.map((country) => (
                <option key={country.en_short_name} value={country.en_short_name}>
                  {country.en_short_name}
                </option>
              ))
            }
          </select>
          {errors.billCountry && touched.billCountry ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.billCountry}</p>
          ) : null}
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
        <button
          type="submit"
          disabled={(Object.keys(errors).length === 0) ? false : true}
          className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-orange-dark ${(Object.keys(errors).length === 0) ? 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-orange-chocolate' : ''}`}
        >
          Valider
        </button>
      </div>
    </div>
  );
};

export default BillingFormBloc;
