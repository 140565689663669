import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { RelayPointDetails } from '../RelayPointDetails';
import { getRelayPointDetailLaunched } from '../../../templates/ShoppingDeliveryPage/reducer';
import { CheckIcon } from '@heroicons/react/outline';

export const ListingRelayPoint = ({ item, index, setDetailsIsOpen, setRelayPointSelected, relayPointSelected }) => {

  const [openDetails, setOpenDetails] = useState(false);
  const showDetails = () => {
    setOpenDetails(true);
  };
  const hideDetails = () => {
    setOpenDetails(false);
  };

  const dispatch = useDispatch();
  const { relayPointDetail, loading, RPDetailStatutCode } = useSelector((state) => ({
    relayPointDetail: state.getIn(['shoppingDeliveryPage', 'relayPointDetail']),
    loading: state.getIn(['shoppingDeliveryPage', 'detailIssLoading']),
    RPDetailStatutCode: state.getIn(['shoppingDeliveryPage', 'RPDetailStatutCode'])
  }));

  const getRelayPointDetail = () => {
    if (item.CP[0] !== '' && item.Pays[0] !== '' && item.Num[0]) {
      dispatch(getRelayPointDetailLaunched({
        country: item.Pays[0],
        zipCode: item.CP[0],
        idRelayPoint: item.Num[0],
        action: "24R",
        resultsNumber: 30,
      }))
    }
  };

  const selectRelayPoint = () => {
    setRelayPointSelected({
      name: item.LgAdr1[0],
      address: item.LgAdr3[0],
      zipCodeCity: item.CP[0] + ' ' + item.Ville[0],
      country: item.Pays[0],
      id: item.Num[0]
    })
  };

  return (
    <>
      <RelayPointDetails
        itemDetail={relayPointDetail}
        index={index}
        showDetails={openDetails}
        hideDetails={() => { hideDetails(); setDetailsIsOpen(false) }}
        selectRelayPoint={() => selectRelayPoint()}
        relayPointSelected={relayPointSelected}
      />
      <div
        aria-hidden="true"
        onClick={() => selectRelayPoint()}
        className={`py-4 pl-4 block cursor-pointer ${relayPointSelected && (relayPointSelected?.id === item.Num[0]) ? 'bg-orange rounded-md' : 'bg-white'}`}
      >
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            {(!relayPointSelected || (relayPointSelected && (relayPointSelected?.id !== item.Num[0]))) && (
              <p className="h-8 w-8 pt-1.5 rounded-full bg-orange-dark text-white text-center">{index + 1}</p>
            )}
            {(relayPointSelected && (relayPointSelected?.id === item.Num[0])) && (
              <p className="h-8 w-8 rounded-full bg-green text-center">
                <CheckIcon className="mx-auto pt-1 h-7 w-7 text-white" />
              </p>
            )}
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-s font-medium text-gray-900 truncate capitalize">{item.LgAdr1[0]}</p>
            <p className="text-xs text-gray-500 truncate capitalize">{item.LgAdr3[0]}</p>
            <p className="text-xs text-gray-500 truncate capitalize">{item.CP[0] + ' ' + item.Ville[0]}</p>
          </div>
          <div className="pr-6">
            <button type="button" onClick={(e) => {
              e.stopPropagation(); // doesnt select current relay point
              showDetails();
              setDetailsIsOpen(true);
              getRelayPointDetail()
            }}
            >
              <InformationCircleIcon className="h-8 w-8 text-grey-lightDark hover:text-orange-darkest" />
            </button>
          </div>
        </div>
      </div>

    </>


  )
};

export default ListingRelayPoint;

