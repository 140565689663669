import React, { Fragment, useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getRelayPointLaunched, getCitySuggestionLaunched } from '../../../templates/ShoppingDeliveryPage/reducer';
import { Listbox, Transition, Combobox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { capitalizeFirst, formatCityName } from '../../../utils/format'
import * as Yup from 'yup';
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// to show new country: add in availableCountry and uncomment country listbox
// (and important add set field country in input zipCode & input city : from alphaCode2 to englishName )
const availableCountry = ['France']

export const SearchRelayPoint = ({ deliveryAddress, getAlpha2Code }) => {
  const classes = useStyles(getStyles());

  const dispatch = useDispatch();
  const { citySuggestion, cityLoading } = useSelector((state) => ({
    citySuggestion: state.getIn(['shoppingDeliveryPage', 'citySuggestion']),
    cityLoading: state.getIn(['shoppingDeliveryPage', 'cityLoading'])
  }));

  const [countrySelected, setCountrySelected] = useState(availableCountry[0]);

  // Init Form Value Search Relay Point
  let initialValues;
  if (deliveryAddress) {
    initialValues = {
      zipCode: deliveryAddress.zipCode,
      city: deliveryAddress.city,
      country: deliveryAddress.country
    };
  } else {
    initialValues = {
      zipCode: '',
      city: '',
      country: countrySelected
    };
  }

  // Form validation schema Search Relay Point
  const validationSchema = Yup.object().shape({
    country: Yup.string().required('Choisissez un pays'),
    zipCode: Yup.string().required('Renseignez un code postal'),
  });

  // Form Data Search Relay Point
  const [searchRelayForm, setSearchRelayForm] = useState({
    country: initialValues.country,
    city: initialValues.city,
    zipCode: initialValues.zipCode,
    action: "24R",
    resultsNumber: 30,
  })

  // search Relay Point function on submit
  const SearchRelayPoint = () => {
    if (searchRelayForm.zipCode !== '' && searchRelayForm.country !== '') {
      dispatch(getRelayPointLaunched({
        country: getAlpha2Code(searchRelayForm.country),
        zipCode: searchRelayForm.zipCode,
        action: "24R",
        resultsNumber: 30,
      }))
    }
  };

  // CITY SUGGESTION AND GET ZIP CODE
  const [showCitySuggest, setShowCitySuggest] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // City suggestion and autocomplete
  const getCitySuggestion = (citySearchValue) => {
    if (citySearchValue && citySearchValue.length > 2) {
      dispatch(getCitySuggestionLaunched({
        country: getAlpha2Code(searchRelayForm.country),
        city: citySearchValue,
        resultsNumber: 15
      }))
    }
  }
  // Force refresh when user choose city suggested
  useEffect(() => {
    SearchRelayPoint();
  }, [refresh]);

  return (
    <div className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => SearchRelayPoint()}
      >
        {({ errors, values, handleChange, setFieldValue }) => (
          <Form className="px-4 py-4 orange">
            <div className="grid grid-cols-12 gap-4">
              <div className="hidden md:block md:col-span-3">
                <label className="hidden" htmlFor="zipCode">Code postal</label>
                <Field
                  id="zipCodeRelay"
                  name="zipCode"
                  type="text"
                  placeholder="Code postal"
                  values={values.zipCode}
                  onChange={(e) => {
                    handleChange(e);
                    setSearchRelayForm((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                    setSearchRelayForm((prevState) => ({  // if zipCode change : reset city fields
                      ...prevState,
                      'city': '',
                    }));
                    setFieldValue('city', "");
                  }}
                  className="w-full rounded-md border border-black bg-white shadow-sm focus:outline-none focus:ring-1 focus:border-orange-dark focus:ring-orange-dark"
                />
              </div>
              <div className="col-span-12 md:col-span-4">
                <Combobox
                  as="div"
                  value={capitalizeFirst(searchRelayForm.city)}
                  onChange={(e) => {
                    setSearchRelayForm((prevState) => ({
                      ...prevState,
                      'zipCode': e.zipCode[0],
                      'city': e.ville[0]
                    }));
                    setFieldValue('zipCode', e.zipCode[0]);
                    setFieldValue('city', e.ville[0]);
                    setRefresh(!refresh);
                  }}>
                  <Combobox.Label className="hidden">Ville</Combobox.Label>
                  <div className="relative">
                    <Combobox.Input
                      className="w-full rounded-md border border-black bg-white pl-3 pr-10 shadow-sm focus:outline-none focus:ring-1 focus:border-orange-dark focus:ring-orange-dark"
                      onChange={(e) => {
                        getCitySuggestion(formatCityName(e.target.value));
                        setShowCitySuggest(true);
                      }}
                      displayValue={searchRelayForm.city.length > 0 ? capitalizeFirst(searchRelayForm.city) : ''}
                    />
                    {citySuggestion?.Commune && (
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </Combobox.Button>
                    )}
                    {(showCitySuggest && !cityLoading && citySuggestion?.Commune) && (
                      <Combobox.Options style={classes.comboboxOption} className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {citySuggestion?.Commune.map((city, key) => (
                          <Combobox.Option
                            key={city + key}
                            value={{ ville: city.Ville, zipCode: city.CP, codePays: city.Pays }}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                active ? 'bg-indigo-600 text-orange-darkest' : 'text-gray-900'
                              )
                            }
                          >
                            {({ active, selected }) => (
                              <>
                                <div className="flex">
                                  <span className={classNames('truncate', selected && 'font-semibold')}>{capitalizeFirst(city.Ville[0])}</span>
                                  <span
                                    className={classNames(
                                      'ml-2 truncate text-gray-500',
                                      active ? 'text-orange-darkest' : 'text-gray-500'
                                    )}
                                  >
                                    {city.CP}
                                  </span>
                                </div>

                                {selected && (
                                  <span
                                    className={classNames(
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                      active ? 'text-white' : 'text-indigo-600'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              </div>
              <div className="col-span-8 md:col-span-3">
                <Listbox
                  value={searchRelayForm.country}
                  onChange={(e) => {
                    handleChange(e);
                    setSearchRelayForm((prevState) => ({
                      ...prevState,
                      'country': e
                    }));
                  }}
                >
                  {({ open }) => (
                    <>
                      <Listbox.Label className="hidden">Pays</Listbox.Label>
                      <div className="relative">
                        <Listbox.Button className="bg-white relative w-full border border-grey-medium rounded-md py-2 focus:outline-none shadow-sm pl-3 pr-10 text-left cursor-default">
                          <span className="block truncate text-grey-lightDark">{searchRelayForm.country}</span>
                          {/* Uncomment here if another available country is added */}
                          {/* <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span> */}
                        </Listbox.Button>
                        {/* Uncomment here if another available country is added */}
                        {/* <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {availableCountry.map((country, key) => (
                              <Listbox.Option
                                key={key}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'text-dark bg-orange' : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                  )
                                }
                                value={country}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {country}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-dark' : 'text-orange',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition> */}
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              <button
                type="submit"
                className="py-2 md:py-0 col-span-4 md:col-span-2 w-full capitalize text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none font-medium rounded-lg text-xxm sm:text-xs md:text-sm text-center"
              >
                Rechercher
              </button>
            </div>
            {errors.zipCode ? (
              <p className="hidden md:block text-orange-darkest text-center md:text-left md:px-2">{errors.zipCode}</p>
            ) : null}
          </Form>
        )}

      </Formik>
    </div>
  )
}

export default SearchRelayPoint;