import React, { useEffect, useState } from 'react';
import i18n from "../../../i18n";
import { useDispatch, useSelector } from 'react-redux';
import { Link, graphql, navigate } from 'gatsby';
import { useCart } from 'react-use-cart';
import { Helmet } from 'react-helmet';
import PlaceHolder from "../../../assets/placeholder.jpg";
import { loginLaunched, getDeliverySimulationLaunched, firstShippingDeliveryLaunched, createCardRegistrationLaunched, createCardRegistrationReset, updateUserLaunched } from '../../state/reducer';
import { getRelayPointLaunched } from './reducer';
import CustomLoader from '../../components/CustomLoader';
import LoginClient from '../../components/LoginClient';
import Layout from '../../components/Layout';
import NavBarMenu from '../../components/NavBarMenu';
import { ChevronLeftIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { PencilAltIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as countries from "i18n-iso-countries";
import { MapModal } from '../../components/MapModal';
import paiement from '../../../assets/secure_paiement.png'
import PriceSummaryBloc from '../../components/ShoppingDelivery/PriceSummary';
import DeliveryFormBloc from '../../components/ShoppingDelivery/DeliveryForm';
import BillingFormBloc from '../../components/ShoppingDelivery/BillingForm';
import generateMeta from '../../utils/meta';

const ShoppingDeliveryPage = ({ data, pageContext }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  // i18n used for translation
  i18n(pageContext.locale);

  // i18n get Alphacode
  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
  const getAlpha2Code = (code) => countries.getAlpha2Code(code, 'en');

  const {
    totalItems,
    items,
  } = useCart();

  // Redirection if cart is empty
  useEffect(() => {
    if (totalItems === 0) {
      navigate("../cart", { replace: true });
    }
  }, []);

  let allSeller = [];
  let itemsTrimmed = [];
  let treatedSellerId = []; // Permits to manage duplicate Seller
  items.map((item, key) => {
    if (!(treatedSellerId.includes(item.sellerId))) {
      allSeller.push({
        corporateName: item.corporateName,
        sellerId: item.sellerId
      })
    }
    treatedSellerId.push(item.sellerId);
    // Permits to have the accurate data to post on DeliverySimulation
    itemsTrimmed = itemsTrimmed.concat([{
      "quantity": item.quantity,
      "offerId": item.offerId
    }])
    return null;
  })

  const [processToPaiement, setProcessToPaiement] = useState(false);
  const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);

  const ValidationSchemaCard = Yup.object({
    cardNumber: Yup.string()
      .matches(/^[\d\s]+$/, 'Le numéro de carte ne doit comporter que des nombres')
      // .min(19, "Votre carte ne possède pas assez de nombre") // 19 = 13 number + space
      // .max(25, "Votre carte dépasse le maximum de nombre autorisé") // 19 number + space
      .required("Code bancaire obligatoire"),
    // .matches((/^?:4[0-9]{16}+$/), 'Wrong Format')
    cardExpirationDate: Yup.string()
      .matches(/^\d+(\/\d+)*$/, 'Le numéro de carte ne doit comporter que des nombres')
      .min(4, "Problème de format (doit être en Mois Année)")
      .max(4, "Problème de format (doit être en Mois Année)")
      .required("Date d'expiration obligatoire"),
    cardCvx: Yup.string()
      .matches(/^\d+$/, 'Le numéro de carte ne doit comporter que des nombres')
      .min(3, "Problème de format")
      .max(4, "Problème de format")
      .required("CVV obligatoire"),
  });

  const { userDynamo, updateUserLoading, relayPointContents, isAuthenticated, isAuthenticating, relayPointLoading, naturalUserMangopayLoading } = useSelector((state) => ({
    userDynamo: state.getIn(['root', 'userDynamo']),
    updateUserLoading: state.getIn(['root', 'updateUserLoading']),
    relayPointContents: state.getIn(['shoppingDeliveryPage', 'relayPointContents']),
    isAuthenticated: state.getIn(['root', 'isAuthenticated']),
    isAuthenticating: state.getIn(['root', 'isAuthenticating']),
    relayPointLoading: state.getIn(['shoppingDeliveryPage', 'isLoading']),
    naturalUserMangopayLoading: state.getIn(['root', 'naturalUserMangopayLoading'])
  }));

  const [customerInfo, setCustomerInfo] = useState(userDynamo?.user);

  let userInfo = customerInfo?.user;
  let userId = userInfo?.id;
  const [telephone, setTelephone] = useState('');

  let dataOnChange = '';
  let deliveryPrice = '...';
  let totalPriceTTC = '...';
  let totalToPayTTC = '...';

  // Calculate price to pay
  let totalPrice = 0;
  let uniqueItemPrice = 0;
  items.forEach((item) => {
    uniqueItemPrice = item.price * item.quantity;
    totalPrice += uniqueItemPrice;
    return null;
  })
  const totalCartItemPriceTTC = Number((totalPrice.toFixed(2)) * 100); // ! Mangopay is paying using cents instead of euros
  useEffect(() => {
    if (userDynamo !== null) {
      setCustomerInfo(userDynamo.user);

      if (userDynamo.user && userDynamo.user.deliveryInformations) {
        setFormValue({
          lastName: userDynamo.user.lastName,
          firstName: userDynamo.user.firstName,
          company: userDynamo.user.company,
          address: userDynamo.user.deliveryInformations.address,
          city: userDynamo.user.deliveryInformations.city,
          zipCode: userDynamo.user.deliveryInformations.zipCode,
          country: userDynamo.user.deliveryInformations.country,
          telephone: userDynamo.user.phone,
          cartItems: itemsTrimmed
        });
        setShowDeliveryForm(false); // Permit to hide delivery form after first update

        setBillingFormValue({
          billLastName: userDynamo.user.lastName,
          billFirstName: userDynamo.user.firstName,
          billCompany: userDynamo.user.company,
          billAddress: userDynamo.user.deliveryInformations.address,
          billCity: userDynamo.user.deliveryInformations.city,
          billZipCode: userDynamo.user.deliveryInformations.zipCode,
          billCountry: userDynamo.user.deliveryInformations.country,
        });
      };
      if (userDynamo.user.phone) {
        setTelephone(userDynamo.user.phone);
      };

      setTotalPriceTCCState({ totalPriceTTC: totalCartItemPriceTTC });

      setFormValueCardRegistration({
        userId: userDynamo.user.id,
        cardNumber: initialValuesCard.cardNumber,
        cardExpirationDate: initialValuesCard.cardExpirationDate,
        cardCvx: initialValuesCard.cardCvx,
        Amount: totalToPayTTCState.totalToPayTTC
      });

    }
  }, [userDynamo]);

  const dispatch = useDispatch();

  // Form Delivery Information
  let initialValues;
  if (userDynamo?.user && userDynamo?.user.deliveryInformations) {
    initialValues = {
      lastName: userDynamo.user.lastName,
      firstName: userDynamo.user.firstName,
      company: userDynamo.user.company,
      address: userDynamo.user.deliveryInformations.address,
      city: userDynamo.user.deliveryInformations.city,
      zipCode: userDynamo.user.deliveryInformations.zipCode,
      country: userDynamo.user.deliveryInformations.country,
      telephone: userDynamo.user.phone || ''
    };
  } else {
    initialValues = {
      lastName: '',
      firstName: '',
      company: '',
      address: '',
      city: '',
      zipCode: '',
      country: '',
      telephone: ''
    };
  }

  // Delivery Form
  const [formValue, setFormValue] = useState({
    lastName: initialValues.lastName,
    firstName: initialValues.firstName,
    company: initialValues.company,
    address: initialValues.address,
    city: initialValues.city,
    zipCode: initialValues.zipCode,
    country: initialValues.country,
    cartItems: itemsTrimmed,
    telephone: initialValues.telephone
  });

  // Billing Form
  const [billingFormValue, setBillingFormValue] = useState({
    billLastName: formValue.lastName,
    billFirstName: formValue.firstName,
    billCompany: formValue.company,
    billAddress: formValue.address,
    billCity: formValue.city,
    billZipCode: formValue.zipCode,
    billCountry: formValue.country,
  });

  // Form Data Paiement Card
  let initialValuesCard = {
    userId: '',
    cardNumber: '',
    cardExpirationDate: '',
    cardCvx: '',
    Amount: ''

  };
  const [formValueCardRegistration, setFormValueCardRegistration] = useState({
    userId: initialValuesCard.userId,
    cardNumber: '',
    cardExpirationDate: '',
    cardCvx: '',
    Amount: initialValuesCard.Amount
  });

  let initialValuesPricing = {
    deliveryPrice: '...',
    totalPriceTTC: '...',
    totalToPayTTC: '...'
  };

  const [availableItems, setAvailableItems] = useState(totalItems);

  const [deliveryPriceState, setDeliveryPriceState] = useState({
    deliveryPrice: initialValuesPricing.deliveryPrice,
  });
  const [totalPriceTTCState, setTotalPriceTCCState] = useState({
    totalPriceTTC: initialValuesPricing.totalPriceTTC,
  });

  const [totalToPayTTCState, setTotalToPayTCCState] = useState({
    totalToPayTTC: initialValuesPricing.totalToPayTTC,
  });

  useEffect(() => { // Permits to change Total à Payer when Livraison is modified
    if (deliveryPriceState) {
      if (deliveryPriceState.deliveryPrice !== "..." && totalPriceTTCState.totalPriceTTC !== "...") { // Permits to not trigger useState when generating first value
        // ! Modification de la condition au dessus ! , danger ? => TODO la changer pour un truc plus propre
        setTotalToPayTCCState(
          {
            totalToPayTTC: (Number(deliveryPriceState.deliveryPrice) + Number(totalPriceTTCState.totalPriceTTC))
          })
      }
    }
  }, [deliveryPriceState]);

  const { createCardRegistrationErrorMessage/*, createCardRegistrationLoading*/ } = useSelector((state) => ({
    createCardRegistrationErrorMessage: state.getIn(['root', 'createCardRegistrationErrorMessage']),
    // createCardRegistrationLoading: state.getIn(['root', 'createCardRegistrationLoading']),
  }));

  const { getDeliverySimulationLoading, deliverySimulation, /*getDeliverySimulationErrorMessage */ } = useSelector((state) => ({
    getDeliverySimulationLoading: state.getIn(['root', 'getDeliverySimulationLoading']),
    // getDeliverySimulationErrorMessage: state.getIn(['root', 'getDeliverySimulationErrorMessage']),
    deliverySimulation: state.getIn(['root', 'getDeliverySimulationContent'])
  }));

  const [chosenDelivery, setChosenDelivery] = useState();
  const [deliveyModeAlreadyChosen, setDeliveyModeAlreadyChosen] = useState([]);
  const [sellerOrderConfiguration, setSellerOrderConfiguration] = useState([]);

  useEffect(() => {
    if (chosenDelivery) {
      let newDeliveryMode = []
      if (deliveyModeAlreadyChosen.includes(chosenDelivery.sellerId)) { // Permits to know if delivery mode is already picked
        sellerOrderConfiguration.map((item, key) => {
          if (item.sellerId === chosenDelivery.sellerId) {
            newDeliveryMode.push(chosenDelivery); // Create a new array without former deliveryMode (permits to update a specific value)
          }
          else {
            newDeliveryMode.push(item);
          }
          return null;
        })
        setSellerOrderConfiguration(newDeliveryMode);
        newDeliveryMode = [];
      }
      else {
        setSellerOrderConfiguration(sellerOrderConfiguration => [...sellerOrderConfiguration, chosenDelivery]);
        setDeliveyModeAlreadyChosen(deliveyModeAlreadyChosen => [...deliveyModeAlreadyChosen, chosenDelivery.sellerId]);
      }
    }
  }, [chosenDelivery]);

  const [missingDeliveryChoice, setMissingDeliveryChoice] = useState(false);
  useEffect(() => {
    let availableSellerDelivery = allSeller.length;
    let totalAvailableItems = totalItems;
    let recalculateTotalPriceTTC = totalCartItemPriceTTC;

    setAvailableItems(totalItems);
    setTotalPriceTCCState({ totalPriceTTC: totalCartItemPriceTTC });
    setDeliveryPriceState({ deliveryPrice: deliveryPriceState.deliveryPrice });

    if (deliverySimulation) {
      deliverySimulation.sellerOrders.forEach((sellerOrder) => {
        if (sellerOrder.deliveryModes.every((deliveryMode) => { return deliveryMode.isEligible === false })) { // check if every deliveryMode is unavailble
          availableSellerDelivery -= 1;
        }
      })
    }

    // One or many sellerOrder is unavaible : we need to update number of item and total price TTC
    if (availableSellerDelivery !== allSeller.length) {
      deliverySimulation.sellerOrders.forEach((sellerOrder) => {
        if (sellerOrder.deliveryModes.every((deliveryMode) => { return deliveryMode.isEligible === false })) {
          sellerOrder.cartItems.forEach((cartItem) => {
            totalAvailableItems -= cartItem.quantity;

            items.forEach((item) => {
              if (item.offerId === cartItem.offerId) {
                recalculateTotalPriceTTC -= ((item.price) * (item.quantity) * 100)
              }
            })
          })
        }
      })
      setAvailableItems(totalAvailableItems);
      setTotalPriceTCCState({ totalPriceTTC: Number(recalculateTotalPriceTTC) });
    }

    if (deliverySimulation && sellerOrderConfiguration.length === availableSellerDelivery) {
      setMissingDeliveryChoice(false);

      deliveryPrice = 0;
      sellerOrderConfiguration.forEach((sellerOrderConf) => {
        deliveryPrice += sellerOrderConf.price;
      })

      setDeliveryPriceState({ deliveryPrice: Number(deliveryPrice) });

      totalToPayTTC = ((Number(totalPriceTTCState.totalPriceTTC)) + (Number(deliveryPrice))).toFixed(0); // tofixed obligated to not loose a cent
      setTotalToPayTCCState({ totalToPayTTC: Number(totalToPayTTC) });

      setFormValueCardRegistration((prevState) => ({ // Permits to generate the amount sent to sagas on paiement
        ...prevState,
        Amount: Number(totalToPayTTC)
      }));
    } else if (deliverySimulation && sellerOrderConfiguration.length !== availableSellerDelivery) {
      setMissingDeliveryChoice(true);
    }
  }, [sellerOrderConfiguration, deliverySimulation]);

  useEffect(() => { // When user cancel paiement to change delivery options 
    if (processToPaiement === false) {
      setDeliveyModeAlreadyChosen([])
      setSellerOrderConfiguration([])
      setDeliveryPriceState({ deliveryPrice: initialValuesPricing.deliveryPrice });
      setTotalToPayTCCState({ totalToPayTTC: initialValuesPricing.totalToPayTTC });
      // TODO : Reset all data to initial values
    }
  }, [processToPaiement]);

  // used for navBarMenu
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];


  // SEARCH AND SET RELAY POINT & SHOW MODAL
  const [relayPointSelected, setRelayPointSelected] = useState(null);

  const [arrayOfRelayPoint, setArrayOfRelayPoint] = useState([]);


  // function to get the list of available relay points
  const getRelayPointArray = () => {
    if (formValue.zipCode !== '' && formValue.country !== '') {
      dispatch(getRelayPointLaunched({
        country: getAlpha2Code(formValue.country),
        zipCode: formValue.zipCode,
        action: "24R",
        resultsNumber: 30,
      }))
    }
  }

  // Get relayPoints && deliverySimulation automatically at first mount if delivery informations is defined
  useEffect(() => {
    if (userDynamo) {
      if (userDynamo.user?.deliveryInformations?.zipCode.length > 3 && userDynamo.user?.deliveryInformations?.country.length > 3) {
        if (relayPointContents === null) {
          getRelayPointArray();
        }
        if (userDynamo.user?.deliveryInformations?.city.length > 2 && itemsTrimmed.length > 0) {
          if (deliverySimulation === null) {
            dispatch(getDeliverySimulationLaunched({
              cartItems: itemsTrimmed,
              address: userDynamo.user?.deliveryInformations?.address,
              city: userDynamo.user?.deliveryInformations?.city,
              zipCode: userDynamo.user?.deliveryInformations?.zipCode,
              country: userDynamo.user?.deliveryInformations?.country
            }));
          }
        }
      }
    }
  }, [userDynamo])

  // Auto update deliverySimulation if cart changed
  useEffect(() => {
    if (deliverySimulation && deliverySimulation.sellerOrders.length !== allSeller.lenght) {
      if (formValue.city.length > 2 && formValue.cartItems.length > 0) {
        dispatch(getDeliverySimulationLaunched(formValue));
      }
    }
  }, [userDynamo]);

  // update of the relay points array available after each request
  useEffect(() => {
    if (relayPointContents) {
      setArrayOfRelayPoint(relayPointContents);
    };
  }, [relayPointLoading])

  // Map Modal
  const [openModal, setOpenModal] = useState(false);
  let body;
  if (typeof window !== "undefined") { // during build window is unavailable
    body = document.querySelector("body");
  }
  const showModal = () => {
    setOpenModal(true);
    if (typeof window !== "undefined") {
      body.style.overflow = "hidden"; // block scroll on body when modal is open
    }
  };
  const hideModal = () => {
    setOpenModal(false);
    if (typeof window !== "undefined") {
      body.style.overflow = "auto";
    }
  };

  // display modal if the customer selects the relais-colis mode and has not yet chosen a relay point
  const relayModeShowModal = (deliveryMode) => {
    if (!relayPointSelected && deliveryMode.includes('relais-colis')) {
      showModal();
    }
  }

  // Set & Show Billing Form
  const [sameAsDelivery, setSameAsDelivery] = useState(true)
  const [showBillingForm, setShowBillingForm] = useState(false);

  // Show Delivery Form
  const [showDeliveryForm, setShowDeliveryForm] = useState(false);
  useEffect(() => {
    if (userDynamo && !userDynamo?.user.deliveryInformations) {
      setShowDeliveryForm(true);
    }
  }, [userDynamo])

  // Submit function Delivery From
  const updateDeliveryAddress = () => {
    let deliveryInformations = {
      address: formValue.address,
      zipCode: formValue.zipCode,
      city: formValue.city,
      country: formValue.country,
    };

    // generate mangopayId & walletId at first time 
    if (!userDynamo.user.mangopayId && !userDynamo.user.deliveryInformations) {
      dispatch(firstShippingDeliveryLaunched({
        lastName: formValue.lastName,
        firstName: formValue.firstName,
        company: formValue.company,
        phone: formValue.telephone,
        deliveryInformations: deliveryInformations
      }));
    } else { // other case : we get delivery simulation and update delivery information
      dispatch(getDeliverySimulationLaunched(formValue));
      dispatch(updateUserLaunched({
        lastName: formValue.lastName,
        firstName: formValue.firstName,
        company: formValue.company,
        phone: formValue.telephone,
        deliveryInformations: deliveryInformations
      }))
    }

    // Auto set billing address when it's same as delivery address
    if (sameAsDelivery) {
      setBillingFormValue({
        billLastName: formValue.lastName,
        billFirstName: formValue.firstName,
        billCompany: formValue.company,
        billAddress: formValue.address,
        billCity: formValue.city,
        billZipCode: formValue.zipCode,
        billCountry: formValue.country,
      });
    }

    // reset delivery fee & totalToPay
    if (deliveryPriceState.deliveryPrice !== "...") {
      setDeliveyModeAlreadyChosen([]);
      setSellerOrderConfiguration([]);
      setRelaisColisNeedChoice(false);
      setRelayPointSelected(null);
      setDeliveryPriceState({ deliveryPrice: initialValuesPricing.deliveryPrice });
      setTotalToPayTCCState({ totalToPayTTC: initialValuesPricing.totalToPayTTC });
    }

    if (!userId) {
      setFormValueCardRegistration((prevState) => ({ // if userId is not defined we have to force it there
        ...prevState,
        userId: userDynamo.user.id,
      }));
    }
  };

  // Check if an billing Form Field is missing 
  const [billingError, setBillingError] = useState(false);
  useEffect(() => {
    setBillingError(false);
    Object.keys(billingFormValue)
      .map(function (key) {
        if (key !== 'billCompany'
          && (billingFormValue[key] === null || billingFormValue[key] === '' || billingFormValue[key].length === 0)) {
          setBillingError(true);
          return;
        }
      });
  }, [billingFormValue]);

  // Check if an delivery Form Field is missing
  const [deliveryError, setDeliveryError] = useState(false);
  useEffect(() => {
    setDeliveryError(false);
    Object.keys(formValue)
      .map(function (key) {
        if ((key !== 'company' && key !== 'cartItems')
          && (formValue[key] === null || formValue[key] === '' || formValue[key].length === 0)) {
          setDeliveryError(true);
          return;
        }
      });
  }, [formValue]);

  // Check if relay point is selected when user choose one delivery mode = "relais-colis"
  const [relaisColisNeedChoice, setRelaisColisNeedChoice] = useState(false)
  useEffect(() => {
    setRelaisColisNeedChoice(false);
    sellerOrderConfiguration.forEach((item) => {
      if (item.deliveryModeId === "relais-colis" && !relayPointSelected) {
        setRelaisColisNeedChoice(true);
      }
    })
  }, [sellerOrderConfiguration, relayPointSelected])

  const validateShippingConfiguration = () => {
    if (!relaisColisNeedChoice || !missingDeliveryChoice) {
      setProcessToPaiement(true);
    }
  }

  const validatePaiement = () => {
    window.scrollTo(0, 0);
    setPaymentIsProcessing(true);

    // format customerOrder et saved in localStorage
    let relayPointAddress = {
      id: null,
      name: null,
      address: null,
      zipCodeCity: null,
      country: null
    };
    if (relayPointSelected) {
      relayPointAddress.id = relayPointSelected.id;
      relayPointAddress.name = relayPointSelected.name;
      relayPointAddress.address = relayPointSelected.address;
      relayPointAddress.zipCodeCity = relayPointSelected.zipCodeCity;
      relayPointAddress.country = relayPointSelected.country;
    };
    let sellerOrders = sellerOrderConfiguration;
    sellerOrders.forEach((sellerOrder) => {
      delete sellerOrder.price;
    })
    if (typeof window !== undefined) {
      window.localStorage.removeItem('customerOrder');
      window.localStorage.setItem("customerOrder", JSON.stringify({
        userId: formValueCardRegistration.userId,
        relayPointAddress: relayPointAddress,
        delivery: {
          address: formValue.address,
          city: formValue.city,
          company: formValue.company,
          country: formValue.country,
          countryCode: getAlpha2Code(formValue.country),
          firstName: formValue.firstName,
          lastName: formValue.lastName,
          telephone: formValue.telephone,
          zipCode: formValue.zipCode
        },
        billingAddress: {
          address: billingFormValue.billAddress,
          city: billingFormValue.billCity,
          company: billingFormValue.billCompany,
          country: billingFormValue.billCountry,
          countryCode: getAlpha2Code(billingFormValue.billCountry),
          firstName: billingFormValue.billFirstName,
          lastName: billingFormValue.billLastName,
          zipCode: billingFormValue.billZipCode,
        },
        payment: {
          method: "MANGOPAY_CB",
          infos: null
        },
        deliverySimulationId: deliverySimulation.id,
        sellerOrderConfiguration: sellerOrders
      }))
    };

    dispatch(createCardRegistrationLaunched({
      userId: formValueCardRegistration.userId,
      cardNumber: formValueCardRegistration.cardNumber.replaceAll(' ', ''),
      cardExpirationDate: formValueCardRegistration.cardExpirationDate.replaceAll('/', ''),
      cardCvx: formValueCardRegistration.cardCvx,
      Amount: formValueCardRegistration.Amount,
    }));

  };

  const resetPaiement = () => {
    dispatch(createCardRegistrationReset());
    setPaymentIsProcessing(false);
    setFormValueCardRegistration({
      userId: formValueCardRegistration.userId,
      cardNumber: initialValuesCard.cardNumber,
      cardExpirationDate: initialValuesCard.cardExpirationDate,
      cardCvx: initialValuesCard.cardCvx,
      Amount: totalToPayTTCState.totalToPayTTC
    })
  };

  // Login Form If user is not login
  const [loginFormValue, setLoginFormValue] = useState({
    email: null,
    password: null,
    from: `../checkout`
  });

  const login = () => {
    dispatch(loginLaunched(loginFormValue));
  };

  let pathName;
  if (typeof window !== "undefined") { // during build window is unavailable
    pathName = window.location.pathname;
  }
  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang={pageContext.locale} />
        <title>{processToPaiement ? 'Paiement' : 'Livraison'}</title>
        {metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css"
          integrity="sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ=="
          crossorigin=""
        />
        <script
          src="https://unpkg.com/leaflet@1.8.0/dist/leaflet.js"
          integrity="sha512-BB3hKbKWOc9Ez/TAwyWxNXeoV9c1v6FIeYiBieIWkpLjauysF18NzgR1MBNBXf8/KABdlkX68nAhlwcDFLGPCQ=="
          crossorigin=""></script>
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      <div className="max-w-screen-2xl mx-auto">
        {(!userDynamo && isAuthenticating) ? <CustomLoader /> : null}
        {(userDynamo && !isAuthenticating)
          && (
            <>
              {/* Page Title Section */}
              {processToPaiement === false && (
                <>
                  <Link to="../cart" className="pl-6 flex text-grey items-center underline">
                    <ChevronLeftIcon
                      className="flex-shrink-0 h-4 w-4 text-grey"
                      aria-hidden="true"
                    />
                    Retour au panier
                  </Link>
                  <h2 className='text-lg md:text-xl font-bold px-5 md:px-24 pt-4'>Informations de <span className="text-orange-dark">livraison</span> <span className="text-sm"> (1/2)</span></h2>
                </>
              )}
              {processToPaiement === true && (
                <>
                  <button
                    type="button"
                    onClick={() => setProcessToPaiement(false)}
                    className="pl-6 flex text-grey items-center underline text-xs focus:ring-0 focus:ring-offset-0 focus:outline-none"
                  >
                    <ChevronLeftIcon
                      className="flex-shrink-0 h-4 w-4 text-grey"
                      aria-hidden="true"
                    />
                    Retour à la livraison
                  </button>
                  <h2 className='text-lg md:text-xl font-bold px-5 md:px-24 pt-4'>Informations de <span className="text-orange-dark">paiement</span> <span className="text-sm"> (2/2)</span></h2>
                </>
              )}
              {/* Main Section */}
              <div className="py-4 flex flex-col lg:flex-row">
                <div className="px-2 w-full lg:w-3/5 xl:w-3/4">
                  {processToPaiement === false && (
                    <>
                      {/* Delivery info & sellerOrder */}
                      {/* Delivery Adress Form */}
                      <div
                        className="w-full lg:w-2/3 xl:w-1/2 px-2 py-3 bg-white sm:px-6 cursor-pointer"
                        aria-hidden="true"
                        onClick={() => { if (!deliveryError) { setShowDeliveryForm(!showDeliveryForm) } }}
                      >
                        <h3 className="text-sm font-medium flex flex-row justify-between">
                          Adresse de livraison
                          {
                            showDeliveryForm ?
                              <ChevronUpIcon className="w-4 h-4" />
                              : <ChevronDownIcon className="w-4 h-4" />
                          }
                        </h3>
                        {(!showDeliveryForm && userDynamo?.user.deliveryInformations) && (
                          <>
                            <p>
                              <span>{formValue.firstName}</span>
                              <span className="pl-1.5">{formValue.lastName}</span>
                            </p>
                            {formValue.company ? <p>{formValue.company}</p> : null}
                            <p>{formValue.address} - {formValue.city} </p>
                          </>
                        )}
                        {(!showDeliveryForm && !userDynamo.user.deliveryInformations) && (
                          <p>Veuillez renseigner une adresse de livraison</p>
                        )}
                      </div>
                      <Formik
                        initialValues={initialValues}
                        validate={(values) => {
                          const errors = {};
                          // For validate Social Reason
                          if (!values.lastName) {
                            errors.lastName = "Nom obligatoire";
                          }
                          if (!values.firstName) {
                            errors.firstName = "Prénom obligatoire";
                          }
                          if (!values.address) {
                            errors.address = "Adresse obligatoire";
                          }
                          if (!values.city) {
                            errors.city = "Ville obligatoire";
                          } else if (/^\d+$/i.test(values.city)) {
                            errors.city = "Format incorrect";
                          }
                          if (!values.zipCode) {
                            errors.zipCode = "Code postal obligatoire";
                          }
                          if (!values.country) {
                            errors.country = "Pays obligatoire";
                          }
                          return errors;
                        }}
                        onSubmit={() => {
                          updateDeliveryAddress();
                          getRelayPointArray();
                        }}
                      >
                        {({ errors, touched, values, handleChange }) => (
                          <Form>
                            {showDeliveryForm && (
                              <DeliveryFormBloc
                                values={values}
                                handleChange={handleChange}
                                setFormValue={setFormValue}
                                errors={errors}
                                touched={touched}
                                telephone={telephone}
                                setTelephone={setTelephone}
                                naturalUserMangopayLoading={naturalUserMangopayLoading}
                                updateUserLoading={updateUserLoading}
                              />
                            )}</Form>)}
                      </Formik>

                      {/* Billing Adress Form */}
                      {userDynamo.user.deliveryInformations ?
                        <div className="w-full bg-white">
                          {sameAsDelivery &&
                            (
                              <div className="w-full lg:w-2/3 xl:w-1/2 px-2 py-3 sm:px-6 text-s font-normal flex cursor-pointer"
                                aria-hidden="true"
                                onClick={() => { setSameAsDelivery(false); setShowBillingForm(true) }}
                              >
                                <CheckCircleIcon className="w-6 h-6 text-orange-darkest" />
                                <span className="pl-2 text-grey-darkest">Utiliser la même adresse de facturation</span>
                              </div>
                            )}
                          <>
                            {!sameAsDelivery && (
                              <>
                                <div
                                  className="w-full lg:w-2/3 xl:w-1/2 px-2 py-3 bg-white sm:px-6 cursor-pointer"
                                  aria-hidden="true"
                                  onClick={() => { if (!billingError) { setShowBillingForm(!showBillingForm) } }}>
                                  <h3 className="text-sm font-medium flex flex-row justify-between">
                                    Adresse de facturation
                                    {
                                      showBillingForm ?
                                        <ChevronUpIcon className="w-4 h-4" />
                                        : <ChevronDownIcon className="w-4 h-4" />
                                    }
                                  </h3>
                                  {!showBillingForm && (
                                    <>
                                      <p>
                                        <span>{billingFormValue.billFirstName}</span>
                                        <span className="pl-1.5">{billingFormValue.billLastName}</span>
                                      </p>
                                      {billingFormValue.billCompany ? <p>{billingFormValue.billCompany}</p> : null}
                                      <p>{billingFormValue.billAddress} - {billingFormValue.billCity} </p>
                                    </>
                                  )}
                                </div>

                                {showBillingForm && (
                                  <Formik
                                    initialValues={billingFormValue}
                                    validate={(values) => {
                                      const errors = {};
                                      if (!values.billLastName || billingFormValue.billLastName.lenght === 0 || values.billLastName.length === 0) {
                                        errors.billLastName = "Nom obligatoire";
                                      }

                                      if (!values.billFirstName || values.billFirstName.length === 0) {
                                        errors.billFirstName = "Prénom obligatoire";
                                      }

                                      if (!values.billAddress || values.billAddress.length === 0) {
                                        errors.billAddress = "Adresse obligatoire";
                                      }

                                      if (!values.billCity || values.billCity.length === 0) {
                                        errors.billCity = "Ville obligatoire";
                                      } else if (/^\d+$/i.test(values.billCity)) {
                                        errors.billCity = "Format incorrect";
                                      }

                                      if (!values.billZipCode || values.billZipCode.length === 0) {
                                        errors.billZipCode = "Code postal obligatoire";
                                      }

                                      if (!values.billCountry || values.billCountry.length === 0) {
                                        errors.billCountry = "Pays obligatoire";
                                      }

                                      return errors;
                                    }}
                                    onSubmit={() => { setShowBillingForm(false) }}
                                  >
                                    {({ errors, touched, values, handleChange }) => (
                                      <Form>
                                        <BillingFormBloc
                                          values={values}
                                          handleChange={handleChange}
                                          setBillingFormValue={setBillingFormValue}
                                          errors={errors}
                                          touched={touched}
                                        />
                                      </Form>
                                    )}
                                  </Formik>
                                )}
                              </>
                            )}
                          </>
                        </div>
                        : null}

                      {/* SellerOrder block & Delivery Mode */}
                      <div class="w-full px-2 sm:px-6 pt-4">
                        <div class="flex justify-start item-start flex-col pt-4 border-t border-dashed border-grey-light">
                          <h3 class="text-sm md:text-semi font-medium">Ma commande :</h3>
                        </div>

                        <div class="w-full space-y-4 md:space-y-6 xl:space-y-8">
                          <div class="flex flex-col justify-start items-start w-full divide-y divide-grey-light">
                            {
                              allSeller.map((seller, sellerKey) => {
                                return (
                                  <div class="py-6 w-full">
                                    <h3 class="pl-2 text-s text-grey-darkest">Vendu et expédié par <span className="text-orange-dark font-medium">{seller.corporateName}</span></h3>
                                    <div class="pl-2 md:pl-4 w-full flex flex-col">
                                      {/* Product Listing by Seller */}
                                      <div class="w-full p-3 space-y-4 md:space-y-2">
                                        {
                                          items.map((product, key) => {
                                            // display sellerOrder without delivery simulation information
                                            if (!deliverySimulation && product.sellerId === seller.sellerId) {
                                              return (
                                                <div key={key} class="flex justify-start flex-row items-center space-x-4">
                                                  <div className="w-1/6 pl-2 lg:pl-0 flex justify-center">
                                                    <Link
                                                      to={product.urlProductPage}
                                                      className="flex lg:flex-col justify-end lg:items-center"
                                                    >
                                                      {product.image ?
                                                        <img
                                                          className="object-scale-down h-20 lg:h-28 w-full md:py-4"
                                                          alt={product.image.description}
                                                          src={product.image.file.url}
                                                        />
                                                        :
                                                        <img
                                                          className="object-scale-down h-20 lg:h-28 w-full md:py-4"
                                                          src={PlaceHolder}
                                                          alt="product"
                                                        />
                                                      }
                                                    </Link>
                                                  </div>
                                                  <div class="flex justify-between items-start w-5/6 flex-row">
                                                    <div class="w-full flex flex-col justify-start items-start space-y-0.5 md:space-y-2">
                                                      <Link to={product.urlProductPage} >
                                                        <h3 class="text-xs md:text-s font-medium">{product.title}</h3>
                                                      </Link>
                                                      <div class="flex justify-start items-start flex-col md:space-y-1.5">
                                                        <div className="flex flex-col items-start md:items-center md:flex-row space-x-0 space-y-1 md:space-x-2 md:space-y-0">
                                                          <p class="text-xs"><span>Prix unit. TTC :  </span> {((product.price * 100) / 100).toFixed(2)}€</p>
                                                          <p class="text-s md:text-sm text-orange-darkest font-medium"><span>Total :  </span> {(product.price * product.quantity).toFixed(2)} €</p>
                                                        </div>
                                                        <p class="text-xs leading-none text-grey-dark"><span>Quantité: </span> {product.quantity}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            } else if (deliverySimulation) {
                                              // display sellerOrder WITH delivery simulation information
                                              return (
                                                deliverySimulation.sellerOrders.map((sellerOrder) => {
                                                  if (product.sellerId === seller.sellerId && sellerOrder.sellerId === product.sellerId) {
                                                    return (
                                                      <div key={key} class="flex justify-start flex-row items-center space-x-4">
                                                        <div className={`w-1/6 pl-2 lg:pl-0 flex justify-center ${(!sellerOrder.deliveryModes[0].isEligible && !sellerOrder.deliveryModes[1].isEligible && !sellerOrder.deliveryModes[2].isEligible && !sellerOrder.deliveryModes[3].isEligible) ? 'opacity-25' : ''}`}>
                                                          <Link
                                                            to={product.urlProductPage}
                                                            className="flex lg:flex-col justify-end lg:items-center"
                                                          >
                                                            {product.image ?
                                                              <img
                                                                className="object-scale-down h-20 lg:h-28 w-full md:py-4"
                                                                alt={product.image.description}
                                                                src={product.image.file.url}
                                                              />
                                                              :
                                                              <img
                                                                className="object-scale-down h-20 lg:h-28 w-full md:py-4"
                                                                src={PlaceHolder}
                                                                alt="product"
                                                              />
                                                            }
                                                          </Link>
                                                        </div>
                                                        <div class="flex justify-between items-start w-5/6 flex-row">
                                                          <div class="w-full flex flex-col justify-start items-start space-y-0.5 md:space-y-2">
                                                            <Link to={product.urlProductPage} >
                                                              <h3 class={`text-xs md:text-s font-medium ${(!sellerOrder.deliveryModes[0].isEligible && !sellerOrder.deliveryModes[1].isEligible && !sellerOrder.deliveryModes[2].isEligible && !sellerOrder.deliveryModes[3].isEligible) ? 'opacity-25' : ''}`}>
                                                                {product.title}
                                                              </h3>
                                                            </Link>
                                                            <div class="flex justify-start items-start flex-col md:space-y-1.5">
                                                              <div class={`${(!sellerOrder.deliveryModes[0].isEligible && !sellerOrder.deliveryModes[1].isEligible && !sellerOrder.deliveryModes[2].isEligible && !sellerOrder.deliveryModes[3].isEligible) ? 'line-through opacity-25' : ''}`}>
                                                                <div className="flex flex-col items-start md:items-center md:flex-row space-x-0 space-y-1 md:space-x-2 md:space-y-0">
                                                                  <p class="text-xs"><span>Prix unit. TTC :  </span> {((product.price * 100) / 100).toFixed(2)}€</p>
                                                                  <p class="text-s md:text-sm text-orange-darkest font-medium"><span>Total :  </span> {(product.price * product.quantity).toFixed(2)} €</p>
                                                                </div>
                                                              </div>
                                                              <p class={`text-xs leading-none ${(!sellerOrder.deliveryModes[0].isEligible && !sellerOrder.deliveryModes[1].isEligible && !sellerOrder.deliveryModes[2].isEligible && !sellerOrder.deliveryModes[3].isEligible) ? 'text-red' : 'text-grey-dark'}`}>
                                                                <span>Quantité: </span>
                                                                {(!sellerOrder.deliveryModes[0].isEligible
                                                                  && !sellerOrder.deliveryModes[1].isEligible
                                                                  && !sellerOrder.deliveryModes[2].isEligible
                                                                  && !sellerOrder.deliveryModes[3].isEligible) ? 'Indisponible' : product.quantity}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                  return null;
                                                })
                                              )
                                            }
                                            return null;
                                          })
                                        }
                                      </div>
                                      {/* Delivery Mode */}
                                      <div class="w-full flex flex-col justify-center py-3">
                                        <div class="md:pl-9">
                                          <fieldset>
                                            <div>
                                              {/* <legend className="text-s font-normal text-grey-darkest">Mode de livraison pour <span className="text-orange-dark">{seller.corporateName}</span> </legend> */}
                                              <legend className="text-s font-normal text-grey-darkest">Mode de livraison</legend>

                                            </div>
                                            <div className="mt-4 space-y-4">
                                              {
                                                (getDeliverySimulationLoading === false && deliverySimulation === null)
                                                && (
                                                  <div>
                                                    Veuillez renseigner votre adresse de livraison pour calculer le montant
                                                  </div>
                                                )
                                              }
                                              {
                                                (getDeliverySimulationLoading === true)
                                                && (
                                                  <div>
                                                    Calcul en cours...
                                                  </div>
                                                )
                                              }

                                              {
                                                (getDeliverySimulationLoading === false && deliverySimulation !== null)
                                                && (
                                                  <div>
                                                    {
                                                      deliverySimulation.sellerOrders.map((sellerOrder, sellerOrderKey) => {
                                                        if (seller.sellerId === sellerOrder.sellerId) {
                                                          return (
                                                            <div>
                                                              {
                                                                sellerOrder.deliveryModes.map((deliveryMode, deliveryModeKey) => {
                                                                  return (
                                                                    <div>
                                                                      <div className="flex items-center">
                                                                        {
                                                                          (deliveryMode.isEligible)
                                                                          && (
                                                                            <>
                                                                              <input
                                                                                id={deliveryMode.code + "-" + seller.sellerId}
                                                                                name={seller.sellerId}
                                                                                type="radio"
                                                                                value={deliveryMode.deliveryPriceWithVAT * 100} // TODO change * 100
                                                                                className="focus:ring-orange-darkest h-4 w-4 text-orange-dark border-orange-darkest"
                                                                                onChange={(e) => {
                                                                                  relayModeShowModal(e.target.id) // show modal only if current delivery mode = relais-colis
                                                                                  // handleChange(e);
                                                                                  dataOnChange = {
                                                                                    "sellerId": seller.sellerId,
                                                                                    "deliveryModeId": deliveryMode.code,
                                                                                    "price": deliveryMode.deliveryPriceWithVAT * 100
                                                                                  }
                                                                                  setChosenDelivery(dataOnChange); // Permits to send only data in an array and to check if the sellerId is already there after
                                                                                }}
                                                                              />
                                                                              <label htmlFor={deliveryMode.code + "-" + seller.sellerId} className="px-3 text-xs md:text-s">
                                                                                {(deliveryMode.code.charAt(0).toUpperCase() + deliveryMode.code.slice(1)) + " (" + deliveryMode.deliveryPriceWithVAT + "€ de livraison)"}
                                                                              </label>
                                                                            </>
                                                                          )
                                                                        }
                                                                      </div>
                                                                      {/* display information about relay point selected */}
                                                                      {sellerOrderConfiguration && sellerOrderConfiguration.map((configuration) => {
                                                                        return (
                                                                          (relayPointSelected
                                                                            && configuration.deliveryModeId === 'relais-colis'
                                                                            && deliveryMode.code === 'relais-colis'
                                                                            && configuration.sellerId === seller.sellerId)
                                                                          && (
                                                                            <div className="w-full xl:w-3/4 2xl:w-1/2 flex flex-row justify-between pb-2">
                                                                              <div className="flex flex-col justify-center text-xs text-grey-darkest">
                                                                                <p>Vous avez choisi le point relais :</p>
                                                                                <p>{relayPointSelected.name}</p>
                                                                                <p>{relayPointSelected.zipCodeCity}</p>
                                                                              </div>
                                                                              <button
                                                                                type="button"
                                                                                onClick={showModal}
                                                                                data-modal-toggle="mapModal"
                                                                                className="flex flex-col items-center justify-end text-black hover:text-orange-chocolate"
                                                                              >
                                                                                <PencilAltIcon className="w-4 h-4" />
                                                                                <span>Modifier</span>
                                                                              </button>
                                                                            </div>

                                                                          )
                                                                        )
                                                                      })}
                                                                    </div>
                                                                  )
                                                                })
                                                              }
                                                              {(!sellerOrder.deliveryModes[0].isEligible && !sellerOrder.deliveryModes[1].isEligible && !sellerOrder.deliveryModes[2].isEligible && !sellerOrder.deliveryModes[3].isEligible) && (
                                                                <p className="block text-xs font-medium">Ce vendeur ne livre pas encore dans votre pays</p>
                                                              )}
                                                            </div>
                                                          )
                                                        }
                                                        return null;

                                                      })
                                                    }
                                                  </div>
                                                )
                                              }
                                            </div>
                                          </fieldset>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {processToPaiement === true && (
                    <>
                      <div className="pt-2 md:pt-5 sm:mt-0 md:px-24 px-5">
                        {
                          (!paymentIsProcessing)
                          && (
                            <>
                              {
                                (!((typeof (totalToPayTTCState.totalToPayTTC) === "number") && (typeof (formValueCardRegistration.Amount) === "number")))
                                && (
                                  <>
                                    <span className="text-orange-darkest text-center md:text-left">
                                      Les modes de livraison n'ont pas tous été sélectionnés, veuillez recommencer l'étape précédente
                                    </span>
                                  </>
                                )
                              }


                              {
                                (formValueCardRegistration.userId === null || formValueCardRegistration.userId === undefined)
                                && (
                                  <span className="text-orange-darkest text-center md:text-left">
                                    Un problème est survenu lors de la récupération de vos informations, veuillez actualiser la page.
                                  </span>
                                )
                              }


                              {
                                ((typeof (totalToPayTTCState.totalToPayTTC) === "number") && (typeof (formValueCardRegistration.Amount) === "number"))
                                && (
                                  <Formik
                                    initialValues={initialValuesCard}
                                    validationSchema={ValidationSchemaCard}
                                    onSubmit={validatePaiement}
                                  >
                                    {({ errors, touched, values, handleChange }) => (
                                      <Form>
                                        <div class="flex flex-wrap gap-3 w-full px-1 py-1 sm:p-5">
                                          <div class="w-full mx-auto sm:max-w-lg">
                                            <label htmlFor="cardNumber" class="relative w-full flex flex-col mb-3">
                                              <span class="font-bold mb-1">
                                                Numéro de carte
                                              </span>
                                              <Field
                                                id="cardNumber"
                                                name="cardNumber"
                                                type="text"
                                                placeholder="0000 0000 0000 0000"
                                                value={formValueCardRegistration.cardNumber}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  const cardValue = e.target.value.replaceAll('  ', ' ')
                                                  setFormValueCardRegistration((prevState) => ({
                                                    ...prevState,
                                                    [e.target.name]: cardValue.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '') // TODO : .replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'')
                                                  }));
                                                }}
                                                className={`rounded-md peer PaymentFormInput pr-2 py-2 border-2 border-gray-200 placeholder-gray-300" type="text"`}
                                              />
                                              <svg xmlns="http://www.w3.org/2000/svg" class="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                                              </svg>
                                            </label>

                                            {errors.cardNumber && touched.cardNumber ? (
                                              <p className="text-orange-darkest text-center md:text-left mb-3">{errors.cardNumber}</p>
                                            ) : null}

                                            <label htmlFor="cardExpirationDate" class="relative w-full flex flex-col mb-3">
                                              <span class="font-bold mb-1">
                                                Date d'expiration (MMYY)
                                              </span>
                                              <Field
                                                id="cardExpirationDate"
                                                name="cardExpirationDate"
                                                type="text"
                                                placeholder="MMYY"
                                                value={(formValueCardRegistration.cardExpirationDate)}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  setFormValueCardRegistration((prevState) => ({
                                                    ...prevState,
                                                    [e.target.name]: e.target.value // TODO : transform into mm/yy
                                                  }));
                                                }}
                                                className={`rounded-md peer PaymentFormInput pr-2 py-2 border-2 border-gray-200 placeholder-gray-300" type="text"`}
                                              />
                                              <svg xmlns="http://www.w3.org/2000/svg" class="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                              </svg>
                                            </label>

                                            {errors.cardExpirationDate && touched.cardExpirationDate ? (
                                              <p className="text-orange-darkest text-center md:text-left mb-3">{errors.cardExpirationDate}</p>
                                            ) : null}

                                            <label htmlFor="cardCvx" class="relative flex-1 flex flex-col mb-3">
                                              <span class="font-bold flex items-center gap-3 mb-1">
                                                CVC/CVV
                                              </span>
                                              <Field
                                                id="cardCvx"
                                                name="cardCvx"
                                                type="text"
                                                placeholder="&bull;&bull;&bull;"
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  setFormValueCardRegistration((prevState) => ({
                                                    ...prevState,
                                                    [e.target.name]: e.target.value
                                                  }));
                                                }}
                                                className={`rounded-md peer PaymentFormInput pr-2 py-2 border-2 border-gray-200 placeholder-gray-300" type="text"`}
                                              />
                                              <svg xmlns="http://www.w3.org/2000/svg" class="absolute bottom-0 left-0 -mb-0.5 transform translate-x-1/2 -translate-y-1/2 text-black peer-placeholder-shown:text-gray-300 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                              </svg>
                                            </label>

                                            {errors.cardCvx && touched.cardCvx ? (
                                              <p className="text-orange-darkest text-center md:text-left mb-3">{errors.cardCvx}</p>
                                            ) : null}

                                          </div>
                                        </div>

                                        <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
                                          <p className="space-x-0.5 pb-2">
                                            <a href={`/${pageContext.locale}/cgv`} target="_blank" className="underline">
                                              En cliquant sur "Payer" vous acceptez nos CGV
                                            </a>
                                          </p>
                                          <button
                                            type="submit"
                                            className={`outline-none w-52 inline-flex justify-center rounded py-4 px-2 border border-transparent text-white focus:outline-none focus:ring-0 focus:ring-offset-0 ${(Object.keys(errors).length !== 0 || (formValueCardRegistration.cardNumber === '' && formValueCardRegistration.cardExpirationDate === '' && formValueCardRegistration.cardCvx === '')) ? 'bg-grey cursor-not-allowed' : 'cursor-pointer bg-orange-dark hover:bg-orange-chocolate hover:font-semibold'}`}
                                          >
                                            Payer {(totalToPayTTCState.totalToPayTTC / 100).toFixed(2)} €  {/* // ! Mangopay is paying using cents instead of euros. */}
                                          </button>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                )
                              }
                            </>
                          )
                        }
                        {
                          (paymentIsProcessing === true)
                          && (
                            <div className="pt-10 pb-6 sm:mt-0 md:px-24 px-5">
                              <div className='text-s text-center md:text-sm font-bold pb-1 md:pb-6'>
                                {
                                  (createCardRegistrationErrorMessage === null)
                                  && (
                                    <div>
                                      <span>
                                        Paiement en cours...<br />Veuillez patienter.<br />
                                      </span>
                                      <span className="pt-2">
                                        Vous allez être redirigé automatiquement.
                                      </span>
                                    </div>
                                  )
                                }

                                {
                                  (createCardRegistrationErrorMessage !== null)
                                  && (
                                    <div>
                                      <span className="text-orange-darkest text-center md:text-left">
                                        {(createCardRegistrationErrorMessage)}
                                      </span>

                                      <div className="px-4 py-3 bg-gray-50 sm:px-6">
                                        <button
                                          type="button"
                                          onClick={() => resetPaiement()}//setProcessToPaiement(false)}
                                          className="w-52 inline-flex justify-left py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-orange-dark hover:bg-orange-chocolate"
                                        >
                                          Recommencer le paiement
                                        </button>
                                      </div>
                                    </div>
                                  )
                                }

                              </div>
                            </div>
                          )
                        }

                        <div class="flex justify-start w-full sm:w-full sm:h-32 sm:px-4 sm:pt-2 my-8">
                          <img src={paiement} alt="Paiement sécurisé" class="h-full object-center sm:object-contain" />
                        </div>
                      </div>
                    </>
                  )}

                </div>

                {/* Summary block */}
                <div className="px-2 w-full lg:w-2/5 xl:w-1/4">
                  <PriceSummaryBloc
                    availableItems={availableItems}
                    totalPriceTTCState={totalPriceTTCState}
                    deliveryPriceState={deliveryPriceState}
                    totalToPayTTCState={totalToPayTTCState}
                    relaisColisNeedChoice={relaisColisNeedChoice}
                    missingDeliveryChoice={missingDeliveryChoice}
                    billingError={billingError}
                    deliveryError={deliveryError}
                    showModal={showModal}
                    validateShippingConfiguration={validateShippingConfiguration}
                    deliverySimulation={deliverySimulation}
                    processToPaiement={processToPaiement}
                  />
                </div>
              </div>
              <MapModal
                showModal={openModal}
                hideModal={hideModal}
                arrayOfRelayPoint={arrayOfRelayPoint}
                formValue={formValue}
                getAlpha2Code={getAlpha2Code}
                setRelayPointSelected={setRelayPointSelected}
                relayPointSelected={relayPointSelected}
              />
            </>
          )
        }
        {
          (!isAuthenticated && !isAuthenticating) &&
          <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
            <p className="text-center mb-4">Veuillez vous connecter ou vous inscrire afin de passer commande</p>
            <LoginClient
              formValue={loginFormValue}
              setFormValue={setLoginFormValue}
              submit={() => login()}
              lang={pageContext?.locale}
              from={pathName}
            />
          </div>
        }
      </div >
    </Layout >
  );
};

export default (ShoppingDeliveryPage);

export const query = graphql`
  query( $locale: String! ) {
    menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
      edges {
        node {
          slug
          categoryName
        }
      }
    }
    menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
            parentCategory {
              slug
              categoryName
              categoryEncartTitle
            }
          } 
        }
      }
    }
    allContentfulCategorySve(filter: { node_locale: { eq: $locale } }) { 
      edges {
        node {
          categoryName
          categoryEncartTitle
          slug
          parentCategory {
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/checkout.md/"}) {
      html
      frontmatter {
          title
          description
          ogtitle
          ogdescription
      }
    }
  }
`;