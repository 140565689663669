import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchRelayPoint } from './SearchRelayPoint';
import { ListingRelayPoint } from './ListingRelayPoint'
import { MapRelayPoint } from './MapRelayPoint';
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";

export const MapModal = ({ showModal, hideModal, arrayOfRelayPoint, formValue, getAlpha2Code, setRelayPointSelected, relayPointSelected }) => {

  const classes = useStyles(getStyles());

  const [detailsIsOpen, setDetailsIsOpen] = useState(false);

  const { errors, RPstatutCode } = useSelector((state) => ({
    errors: state.getIn(['shoppingDeliveryPage', 'errors']),
    RPstatutCode: state.getIn(['shoppingDeliveryPage', 'RPstatutCode'])
  }));

  return (
    showModal && (
      <div
        id="mapModal"
        tabIndex="-1"
        aria-hidden="true"
        style={classes.largeModalBackdrop}
        className="overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full md:inset-0 h-modal"
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
      >
        <div
          aria-hidden="true"
          className="relative mx-auto p-4 w-full max-w-7xl h-full max-h-full h-auto"
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 className="text-s sm:text-base md:text-lg font-semibold text-gray-900 dark:text-white">
                Choisir mon point relais
              </h3>
              <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={hideModal}>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
              </button>
            </div>
            <SearchRelayPoint deliveryAddress={formValue} getAlpha2Code={getAlpha2Code} />
            {/* Modal body  */}
            <div
              className="px-4 w-full flex flex-col md:flex-row"
              style={classes.dynamicHeight}
            >
              <div className="hidden md:block md:w-1/2">
                <div className={`h-full hidden md:block mr-6`}>
                  {(!errors || (RPstatutCode !== "0" && arrayOfRelayPoint.length !== 0)) && (
                    <div className={`h-full relative ${(RPstatutCode === "0" && arrayOfRelayPoint.length === 0) ? 'hidden' : ''} `}>
                      <div className={`h-full static ${detailsIsOpen ? 'overflow-y-hidden' : 'overflow-y-scroll'}`}>
                        {!errors && arrayOfRelayPoint.length > 0 && arrayOfRelayPoint.map((item, index) => (
                          <ListingRelayPoint
                            item={item}
                            index={index}
                            key={index}
                            setDetailsIsOpen={setDetailsIsOpen}
                            setRelayPointSelected={setRelayPointSelected}
                            relayPointSelected={relayPointSelected}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {(errors || (RPstatutCode === "0" && arrayOfRelayPoint.length === 0)) && (
                    <p>Aucun resultat pour ce code postal</p>
                  )}
                </div>
              </div>
              <div className="w-full relative md:w-1/2">
                <div className="relative">
                  {(!errors || (RPstatutCode !== "0" && arrayOfRelayPoint.length !== 0)) && (
                    <MapRelayPoint
                      arrayOfRelayPoint={arrayOfRelayPoint}
                      setDetailsIsOpen={setDetailsIsOpen}
                      setRelayPointSelected={setRelayPointSelected}
                      relayPointSelected={relayPointSelected}
                    />
                  )}
                  {(errors || (RPstatutCode === "0" && arrayOfRelayPoint.length === 0)) && (
                    <p>Aucun resultat pour ce code postal</p>
                  )}
                </div>
              </div>
            </div>

            {/* <!-- Modal footer --> */}
            <div style={classes.modalFooter} className="flex justify-center items-center px-6 py-4 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
              <button
                onClick={hideModal}
                type="button"
                className="capitalize text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-chocolate font-medium rounded-lg text-sm px-5 py-1 text-center"
              >
                {relayPointSelected ? 'Confirmer' : 'Fermer'}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
export default MapModal;