import React from 'react';
import { Field } from 'formik';
import countriesJSON from '../../../utils/countries.json';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

const DeliveryFormBloc = ({
  values,
  handleChange,
  setFormValue,
  errors,
  touched,
  telephone,
  setTelephone,
  naturalUserMangopayLoading,
  updateUserLoading
}) => {

  return (
    <div className="w-full xl:w-2/3 2xl:w-1/2 px-2 bg-white sm:p-6">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-6 lg:col-span-3">
          <label className="block text-s font-medium" htmlFor="lastName">
            Nom*
          </label>
          <Field
            id="lastName"
            name="lastName"
            type="text"
            values={values.lastName}
            onChange={(e) => {
              handleChange(e);
              setFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
            style="appearance: none"
          />
          {errors.lastName && touched.lastName ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.lastName}</p>
          ) : null}
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label className="block text-s font-medium" htmlFor="firstName">
            Prénom*
          </label>
          <Field
            id="firstName"
            name="firstName"
            type="text"
            values={values.firstName}
            onChange={(e) => {
              handleChange(e);
              setFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.firstName && touched.firstName ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.firstName}</p>
          ) : null}
        </div>
        <div className="col-span-6 sm:col-span-3 lg:col-span-3">
          <label className="block text-s font-medium" htmlFor="company">
            Société
          </label>
          <Field
            id="company"
            name="company"
            type="text"
            values={values.company}
            onChange={(e) => {
              handleChange(e);
              setFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.company && touched.company ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.company}</p>
          ) : null}
        </div>

        <div className="col-span-6 lg:col-span-3">
          <label htmlFor="phoneNumber" className="block text-s font-medium">
            Numéro de téléphone*
          </label>

          {/* PhoneInput field : go to global css to customize it */}
          <PhoneInput
            international
            defaultCountry="FR"
            name="tel"
            autoComplete="tel-country-code"
            placeholder="Numéro de téléphone"
            value={values.telephone || telephone}
            onChange={(e) => {
              if (e === undefined) {
                e = '' // Permits to remove former error when indicator was deleted
              }
              setTelephone(e)
              setFormValue((prevState) => ({
                ...prevState,
                telephone: e
              }));
            }}
          />
          {isValidPhoneNumber(telephone) === false
            ? (
              <p className="text-orange-darkest text-center md:text-left">Numéro incorrect</p>
            )
            : null}
        </div>
        <div className="col-span-6">
          <label htmlFor="address" className="block text-s font-medium">
            Adresse*
          </label>
          <Field
            id="address"
            name="address"
            type="text"
            values={values.address}
            onChange={(e) => {
              handleChange(e);
              setFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.address && touched.address ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.address}</p>
          ) : null}
        </div>
        <div className="col-span-6 sm:col-span-6 lg:col-span-3">
          <label htmlFor="city" className="block text-s font-medium">
            Ville*
          </label>
          <Field
            id="city"
            name="city"
            type="text"
            values={values.city}
            onChange={(e) => {
              handleChange(e);
              setFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.city && touched.city ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.city}</p>
          ) : null}
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="zipCode" className="block text-s font-medium">
            CP*
          </label>
          <Field
            id="zipCode"
            name="zipCode"
            type="text"
            values={values.zipCode}
            onChange={(e) => {
              handleChange(e);
              setFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          />
          {errors.zipCode && touched.zipCode ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.zipCode}</p>
          ) : null}
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-3 ">
          <label htmlFor="country" className="block text-s font-medium">
            Pays*
          </label>
          <select
            id="country"
            name="country"
            type="text"
            value={values.country}
            onChange={(e) => {
              handleChange(e);
              setFormValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));
            }}
            className="w-full rounded border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"

          >
            <option value=""> Choisissez un pays </option>
            {
              countriesJSON.map((country) => (
                <option key={country.en_short_name} value={country.en_short_name}>
                  {country.en_short_name}
                </option>
              ))
            }
          </select>
          {errors.country && touched.country ? (
            <p className="text-orange-darkest text-center md:text-left">{errors.country}</p>
          ) : null}
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
        <button
          type="submit"
          disabled={!isValidPhoneNumber(telephone) || naturalUserMangopayLoading}
          className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-orange-dark ${(isValidPhoneNumber(telephone) && Object.keys(errors).length === 0) ? 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-orange-chocolate' : ''}`}
        >
          {(naturalUserMangopayLoading || updateUserLoading) ? "Enregistrement" : "Mettre à jour l'adresse"}
        </button>
      </div>
    </div>
  );
};

export default DeliveryFormBloc;
